import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from "../StyledComponents/SubmitButton"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { signIn } from '../../api/auth';

import './auth.scss';  // Import the CSS file

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        setErrorMessage('')
        event.preventDefault();
        setIsSubmitting(true);
        const userData = {
            email: email.toLowerCase(),
            password,
        };

        signIn(userData)
            .then(response => {
                setIsSubmitting(false);
                if (response.status === 200) {
                    localStorage.setItem('userId', response.data.userId)
                    navigate('/questions')
                }
            })
            .catch(error => {
                console.error('There was an error signing in!', error);
                setErrorMessage('Invalid email or password.');
                setIsSubmitting(false);
            })
    };

    return (
        <>
            <div className="form-header">
                <h4>
                    Welcome to the Injury Impact Severity Score (IISS)
                    triage tool which provides on-field clinical decision support
                </h4>
            </div>
            <Form className="form-container" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text>
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Form.Text className="reset-password" onClick={() => navigate("/resetpassword")}>
                        Forgot your password? Click HERE
                    </Form.Text>
                </Form.Group>

                {errorMessage &&
                    <>
                        <p className="error-message">{errorMessage}</p>
                    </>

                }

                <Button className="w-100 mt-3" variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Logging in...' : 'Login'}
                </Button>
            </Form>
            <div className="form-footer">
                <p>
                    Don't have an account?
                </p>
                <SubmitButton type="button" centered onClick={() => navigate("/register")}>
                    Register
                </SubmitButton>
            </div>
        </>
    );
};

export default SignIn;
