import { LoginApiUrl } from "./apiConfig";
import axios from "axios";

export const registerUser = (data) => {
    return axios.post(`${LoginApiUrl}/user/register`, data);
};

export const signIn = (data) => {
    return axios.post(`${LoginApiUrl}/user/login`, data);
};

export const sendResetPwEmail = (data) => {
    return axios.post(`${LoginApiUrl}/misc/sendResetPwEmail`, data);
};