import React from 'react'

import Brand from './Brand'

const HeaderWithBrand = ({ user, routeGroup, onboardingPercent }) => {
  return (
    <>
      <Brand />
    </>
  )
}

export default HeaderWithBrand
