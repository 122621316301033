import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from "../StyledComponents/SubmitButton"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { sendResetPwEmail } from '../../api/auth';

import './auth.scss';  // Import the CSS file

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        setErrorMessage('')
        event.preventDefault();
        setIsSubmitting(true);

        const formData = {
            email: email.toLowerCase()
        }

        try {
            await sendResetPwEmail(formData);
            setSuccessMessage(`An email with instructions on how to reset your password has been sent to ${formData.email}.`);
        } catch (error) {
            console.error("Error sending password reset email:", error);
            if (error.response && error.response.status === 404) {
                // Handle 404 Not Found error
                setErrorMessage('No account with the provided email. Please check your email and try again.');
            } else {
                // Handle other errors (e.g., 500 Internal Server Error)
                setErrorMessage('An error occurred while sending the password reset email. Please try again later.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="form-header">
                <h4 className="mb-3 mt-4">Reset Password</h4>
            </div>
            <Form className="form-container" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text>
                        Please type in the email used when registering.
                    </Form.Text>
                </Form.Group>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}

                <Button className="w-100" variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send Reset Password Link'}
                </Button>
            </Form>
            <div className="form-footer">
                <SubmitButton type="button" centered onClick={() => navigate("/")}>
                    &lt; Back
                </SubmitButton>
            </div>
        </>
    );
};

export default ResetPassword;
