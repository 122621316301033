import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HeaderWithBrand from './components/Header/HeaderWithBrand';
import Login from './components/SignIn/Login';
import Register from './components/SignIn/Register'
import ResetPassword from './components/SignIn/ResetPassword';
import DecisionTree from './components/DecisionTree';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Login />
    ),
  },
  {
    path: "/register",
    element: (
      <Register />
    ),
  },
  {
    path: "/resetpassword",
    element: (
      <ResetPassword />
    ),
  },
  {
    path: "/questions",
    element: (
      <DecisionTree />
    ),
  }
]);

function App() {
  return (
    <div id="app" className="App">
      <HeaderWithBrand />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
