let LoginApiUrl;
const LoginApiUrls = {
    production: "https://api.powerofpatients.com",
    test: "https://test-api.powerofpatients.com",
    developmentLAN: `https://${window.location.hostname}`,
    development: "http://localhost:4242",
};

if (window.location.hostname.startsWith("localhost")) {
    LoginApiUrl = LoginApiUrls.development;
} else if (window.location.hostname.startsWith("192.168")) {
    LoginApiUrl = LoginApiUrls.developmentLAN;
} else if (window.location.hostname.startsWith("test")) {
    LoginApiUrl = LoginApiUrls.test;
} else {
    LoginApiUrl = LoginApiUrls.production;
}

let ApiUrl;
const ApiUrls = {
    production: "https://api.powerofpatients.com:445",
    test: "https://test-api.powerofpatients.com:445",
    development: "http://localhost:4000",
    developmentLAN: `https://${window.location.hostname}`,
};

if (window.location.hostname.startsWith("localhost")) {
    ApiUrl = ApiUrls.development;
} else if (window.location.hostname.startsWith("192.168")) {
    ApiUrl = ApiUrls.developmentLAN;
} else if (window.location.hostname.startsWith("test")) {
    ApiUrl = ApiUrls.test;
} else {
    ApiUrl = ApiUrls.production;
}

export { LoginApiUrl, ApiUrl }